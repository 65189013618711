import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col } from 'react-bootstrap';

import { Section, Text } from '../../components/Core';
import ContacusImage from '../../assets/image/png/contactus_updated.png';
import { device } from '../../utils';

const SectionStyled = styled(Section)`
    background-image: url(${ContacusImage});
    background-size: cover;
    height: 320px;
    background-position: center;
    margin-top: 64px;
    @media ${device.lg} {
        height: 680px;
        margin-top: 76px;
        background-position: 0 28%;
    }
`;

const Hero = ({ title, children, ...rest }) => {
    return (
        <>
            <SectionStyled
                pt={['120px!important', null, '300px!important']}
                pb={['120px!important', null, '300px!important']}
                {...rest}
            >
                <Container>
                    <Row>
                        <Col>
                            <div>
                                <Text>{children}</Text>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </SectionStyled>
        </>
    );
};
export default Hero;
